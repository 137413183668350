import { alpha, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import 'jspdf-autotable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainBorderRadius, TableHeader } from '../../../app/AppStyles';
import { SearchKeyFilter, GetDate, GetTime } from '../../../app/AppUtils';
import ContentLoader from '../../subcomponents/ContentLoader';
import SearchField from '../../subcomponents/SearchField';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import ExportExcelTravelCosts from './ExportExcelTravelCosts';
import { fetchTravelCostMoments, fetchTravelCostReport } from './reportTravelCostsSlice';

export default function ReportTravelCostsTab() {
    const dispatch = useDispatch();

    const [searching, setSearching] = useState(false);
    const [exported, setExported] = useState(false);
    const [selectedMoment, setSelectedMoment] = useState("");
    const [travelCostReportExcel, setTravelCostReportExcel] = useState();
    const [filter, setFilter] = useState("");

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { travelCostMoments, travelCostMomentsStatus, travelCostReport, travelCostReportStatus } = useSelector((state) => state.reports.travelCosts);

    useEffect(() => {
        dispatch(fetchTravelCostMoments(exported));
    }, [dispatch, exported]);

    useEffect(() => {
        if (travelCostReport) {
            const newArray = travelCostReport.map(({ id, ...keepAttrs }) => keepAttrs)
            setTravelCostReportExcel(newArray)
        };
    }, [travelCostReport]);

    useEffect(() => {
        if (travelCostReportStatus === "success" || travelCostReportStatus === "failed")
            setSearching(false);
    }, [travelCostReportStatus]);

    const handleSearchClick = () => {
        setSearching(true);
        dispatch(fetchTravelCostReport(selectedMoment?.momentId));
    };

    const handleSearchChange = (e) => {
        if (selectedMoment)
            setSelectedMoment("");

        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    const handleFilterResult = (item) => {
        return SearchKeyFilter(item, ["performanceNumber", "activityName"], filter);
    }

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - travelCostReport?.length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? travelCostReport?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : travelCostReport)?.map(item =>
                        <TableRow key={item.id} sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="center">{item.studentIban}</TableCell>
                            <TableCell align="left">{item.studentFirstName}</TableCell>
                            <TableCell align="left">{item.studentLastName}</TableCell>
                            <TableCell align="center" padding="checkbox">{item.studentNumber}</TableCell>
                            <TableCell align="center">{item.studentResidence}</TableCell>
                            <TableCell align="center" padding="checkbox">{Intl.NumberFormat('nl-NL', { style: "currency", currency: "EUR", }).format(item.cost)}</TableCell>
                            <TableCell align="center" padding="checkbox">{item.distance} {resourceFinal[appLanguage].words.KILOMETRES}</TableCell>
                            <TableCell align="center" padding="checkbox">{item.transportType}</TableCell>
                            <TableCell align="center" padding="checkbox">{item.approved ? resourceFinal[appLanguage].words.YES : resourceFinal[appLanguage].words.NO}</TableCell>
                            <TableCell align="center" padding="checkbox">{GetDate(item.dateApproved)} ({GetTime(item.dateApproved)})</TableCell>
                            <TableCell align="center" padding="checkbox">{item.approvedBy}</TableCell>
                            <TableCell align="center">{item.activityName}</TableCell>
                            <TableCell align="center">{item.performanceName}</TableCell>
                            <TableCell align="center" padding="checkbox">{item.performanceNumber}</TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? travelCostReport?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : travelCostReport)?.length === 0 && <TableRow><TableCell colSpan={13}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 63 * emptyRows }}><TableCell colSpan={13} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[10, 25, 50,
                                {
                                    label: travelCostReport?.length < 100 ? resourceFinal[appLanguage].words.ALL : 100,
                                    value: travelCostReport?.length < 100 ? -1 : 100
                                }
                            ]}
                            count={travelCostReport ? travelCostReport?.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                <Box sx={{ display: 'flex', bgcolor: theme => alpha(theme.palette.white.main, 0.3), borderRadius: MainBorderRadius, p: 1 }}>
                    <Box sx={{ display: 'flex', mr: 1, alignItems: 'center' }}>
                        <FormControl disabled={travelCostMomentsStatus !== "success"}>
                            <InputLabel>{resourceFinal[appLanguage].words.PERFORMANCE}</InputLabel>
                            <Select onClose={() => setFilter("")} sx={{ width: 240 }} label={resourceFinal[appLanguage].words.PERFORMANCE} value={selectedMoment} onChange={(event) => setSelectedMoment(event.target.value)}>
                                <Box sx={{ mx: 1, mb: 0.5 }}>
                                    <SearchField func={handleSearchChange} />
                                </Box>
                                {travelCostMoments?.filter(handleFilterResult)?.map((item) =>
                                    <MenuItem key={item.momentId} value={item}>{`${item.performanceNumber} - ${item.activityName}`}</MenuItem>
                                ) ?? []}
                            </Select>
                        </FormControl>
                        <Button variant="outlined" color="secondary" sx={{ height: 1, mx: 1 }} disabled={!selectedMoment} onClick={handleSearchClick}>{resourceFinal[appLanguage].words.REPORT_GENERATE}</Button>
                        <FormGroup>
                            <FormControlLabel sx={{ alignSelf: 'center' }} control={<Checkbox checked={exported} onChange={() => setExported(!exported)} />} label={`${resourceFinal[appLanguage].words.EXPORTED}`} />
                        </FormGroup>
                    </Box>
                    <ExportExcelTravelCosts
                        disabled={searching || !travelCostReport}
                        jsonData={travelCostReportExcel}
                        fileName={`${resourceFinal[appLanguage].words.REPORT_TRAVEL_COSTS} - ${selectedMoment?.activityName} - ${selectedMoment?.performanceName ? selectedMoment?.performanceName + " - " + selectedMoment?.performanceNumber : selectedMoment?.performanceNumber}`}
                        momentId={selectedMoment?.momentId}
                    />
                </Box>
                <ContentLoader successCondition={searching === false} errorCondition={travelCostReportStatus === "failed"} content={
                    <TableContainer>
                        <Table size="small" id="#report">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.USER_DATA_IBAN}</TableCell>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME_FIRST}</TableCell>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME_LAST}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader, width: 200 }}>{resourceFinal[appLanguage].words.STUDENT}{resourceFinal[appLanguage].words.NUMBER.toLowerCase()}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.USER_DATA_CITY}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.COST}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.DISTANCE}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.TRANSPORT_TYPE}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.APPROVED}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.APPROVED_ON}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.APPROVED_BY}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ACTIVITY}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PERFORMANCE}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PERFORMANCE}{resourceFinal[appLanguage].words.NUMBER.toLowerCase()}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableFragment />
                        </Table>
                    </TableContainer>
                } />
            </Box>
        </>
    );
}
